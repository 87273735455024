import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { showNotification } from "features/common/headerSlice";
import { useDispatch } from "react-redux";
import { setProductsTypeDetails, setProductCategoryDetails } from "app/redux/slices/productSlice";

const baseURL = process.env.REACT_APP_PUBLIC_BASE_URL
// const baseURL =  process.env.REACT_APP_PUBLIC_BASE_URL;
const queryClient = new QueryClient();

// Create a function-based API service
function createApiService() {
  const api = axios.create({
    baseURL,
    timeout: 1000 * 60 * 2, // Set a timeout of 5 seconds
    headers: {
      "Content-Type": "application/json",
    },
  });
  // Add an interceptor for token authorization
  api.interceptors.request.use(
    (config) => {
      // Retrieve the token from local storage or wherever you have it corpsd
      const token = localStorage.getItem("token"); // Replace with your actual token storage mechanism

      // If a token exists, add it to the request headers
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // Generic function to make GET requests
  async function get(endpoint, params = {}) {
    try {
      const response = await api.get(endpoint, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Generic function to make POST requests
  async function post(endpoint, data = {}) {
    try {
      const response = await api.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Generic function to make PUT requests
  async function put(endpoint, data = {}) {
    try {
      const response = await api.put(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Generic function to make DELETE requests
  async function remove(endpoint, user) {
    try {
      const response = await api.delete(endpoint, user);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Add additional custom API methods as needed
  async function login(data = {}) {
    try {
      return await post("admins/login", data);
      // return response;
    } catch (error) {
      throw error;
    }
  }

  async function registerAdmin(data) {
    try {
      const response = await post("admins/register", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAdminRoles() {
    try {
      const response = await get("startup/adminroles");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAllSubAdmin() {
    try {
      const response = await get("admins/admins");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getDemoRequests() {
    try {
      const response = await get("admins/demo/request");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getResetPasswordLink({ email }) {
    try {
      const response = await get("admins/password-link", { email });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUsersByType({ userType }) {
    try {
      const response = await get(`admins/usertype?usertype=${userType}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUsers() {
    try {
      const response = await get(`admins/usertype`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getPlans(type) {
    try {
      const response = await get(`admins/corporates?plan=${type}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getCorpEntities() {
    try {
      const response = await get(`admins/corporate/pre-registration`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUserDetails(id) {
    try {
      const response = await get(`admins/user/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getSellers() {
    try {
      const response = await get(`admins/seller`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getDashboardTopCardDetails(id) {
    try {
      const response = await get(`admins/admins/dashtopcards`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function updateProfile(data) {
    try {
      const response = await put("admins/profile", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async function updatePassword(data) {
    try {
      const response = await put("admins/password", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateSubAdmin(data) {
    try {
      const response = await put("admins/status", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateUsersStatus(data) {
    try {
      const response = await put("admins/status/users", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateDemoRequestStatus(data) {
    try {
      const response = await put("admins/demo/request/status", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async function upgradeCorporateUser({ id, data }) {
    try {
      const response = await put(`admins/corporate/${id}/upgrade`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function deleteRequest(id) {
    try {
      const response = await remove(`admins/demo/request/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function updateDemoDuration(duration) {
    try {
      const response = await api.patch(`admins/demo/request/duration`, {
        duration: duration,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getDemoDuration() {
    try {
      const response = await get(`corporate/demo/request/duration`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getAllProducts({ page = 1, limit = 50, status = "pending" }) {
    try {
      const response = await get(
        `admins/products/?page=${page}&limit=${limit}&status=${status}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getProductTypes() {
    try {
      const response = await get(
        `startup/product-type`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function postProductType(payload) {
    try {
      const response = await post(
        `startup/product-type`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function updateProductType(payload) {
    try {
      const response = await put(
        `startup/product-type/${payload?._id}`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getProductCategory(id) {
    try {
      const response = await get(
        `startup/categories/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function postProductCategory(payload) {
    try {
      const response = await post(
        `startup/category`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function editProductCategory (payload) {
    try {
      return await put(
        `startup/category/update/${payload?._id}`, payload
      );
    } catch (error) {
      throw error;
    }
  }
  async function updateProductStatus(payload) {
    try {
      const response = await put(`admins/products/status`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getAllCategories() {
    try {
      const response = await get(`admins/categories`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function setCategoryCommission(payload) {
    try {
      const response = await put(`admins/categories`, {
        update: {
          commission: payload,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // LOGISTICS
  // invitation api
  async function logisticInvite(data) {
    try {
      const response = await post("logistics/logistic-invite", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  // get all invites
  async function getAllinvites() {
    try {
      const response = await get(`logistics/alllogistics`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Delete an invites
  async function deleteInvites(id) {
    try {
      const response = await remove(`logistics/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // DELIVERY COST
  async function getAllDeliverycost() {
    try {
      const response = await get(`logistics/deliverycost`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleDeliverycost(id) {
    try {
      const response = await get(`logistics/deliverycost/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // update
  async function updateDeliverycost({ id, data }) {
    console.log("deliverycostID", id);
    console.log("deliverycostData", data);
    try {
      const response = await put(`logistics/editdeliverycost/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  // ORDERSTATES
  async function getAllOrders() {
    try {
      const response = await get(`logistics/orders/allorder`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleOrder(id) {
    console.log("this is ID", id)
    try {
      const response = await get(`logistics/orders/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleOrder2(id) {
    console.log("this is orderID", id)
    try {
      const response = await get(`logistics/orders/order-items/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getOrderstats
  async function getOrderstats() {
    try {
      const response = await get(`logistics/stats/orderstats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getLogisticsOrderStats
  async function getLogisticsOrderStats() {
    try {
      const response = await get(`logistics/stats/logisticorderstats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getLogisticsOrderStats
  async function getLogisticsVotes() {
    try {
      const response = await get(`logistics/logisticvotes/allvotes`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // update
  async function endPriceVote({ id, data }) {
    // console.log("deliverycostID", id);
    // console.log("deliverycostData", data);
    try {
      const response = await put(`logistics/pricevote/end/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  //MarketPlace Demands
  async function getMarketDemands() {
    try {
      const response = await get(`mktplace/demands`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

    //MarketPlace Demands
    async function getAdminMarketOrders() {
      try {
        const response = await get(`admins/market/orders`);
        return response;
      } catch (error) {
        throw error?.response?.data || error;
      }
    }
  

  //MarketPlace Demands
  async function getAdminMarketDemands() {
    try {
      const response = await get(`admins/market/demands`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //closed MarketPlace Demands

  async function getAdminClosedDemands(page, limit) {
    try {
      const response = await get(`admins/market/demands/closed?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }


  //single market place dmand
  async function getSingleMarketDemand(id) {
    try {
      const response = await get(`admins/market/demand/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getCompletedMarketDemand (id){
        try {
      return await get(`admins/market/demand/closed/${id}`);
    } catch (error) {
      throw error;
    }
  }

   //
   async function getTransactions() {
    try {
      return await get(`admins/transactions`);
      // return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //single market demand suppliers
  async function getSingleDemandSuppliers(id) {
    try {
      const response = await get(`admins/market/demand/${id}/suppliers`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //accept single supplier
  async function acceptSupplier(payload) {
    try {
      return await put(`admins/market/supply/${payload?.id}`, payload);

    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //accept single supplier
  async function generateCheckoutCode(payload) {
    try {
      return await post(`admins/market/demands/generateCode`, payload);
    } catch (error) {

      throw error?.response?.data || error;
    }
  }

   //close demand
   async function closeMarketDemand(payload) {
    try {
      return await put(`admins/market/demand/${payload}/close`,);
    } catch (error) {

      throw error?.response?.data || error;
    }
  }
  return {
    get,
    put,
    post,
    login,
    remove,
    getPlans,
    getUsers,
    getSellers,
    updateProfile,
    registerAdmin,
    getAdminRoles,
    deleteRequest,
    getAllSubAdmin,
    getUserDetails,
    updateSubAdmin,
    updatePassword,
    getUsersByType,
    getAllProducts,
    getDemoRequests,
    getCorpEntities,
    getDemoDuration,
    updateUsersStatus,
    updateDemoDuration,
    updateProductStatus,
    upgradeCorporateUser,
    getResetPasswordLink,
    updateDemoRequestStatus,
    getDashboardTopCardDetails,
    logisticInvite,
    getAllinvites,
    deleteInvites,
    getAllDeliverycost,
    getSingleDeliverycost,
    updateDeliverycost,
    getAllOrders,
    getSingleOrder,
    getSingleOrder2,
    getOrderstats,
    getLogisticsOrderStats,
    getLogisticsVotes,
    endPriceVote,
    getAllCategories,
    setCategoryCommission,
    getMarketDemands,
    getSingleMarketDemand,
    getCompletedMarketDemand,
    getSingleDemandSuppliers,
    acceptSupplier,
    getAdminMarketDemands,
    getAdminMarketOrders,
    getAdminClosedDemands,
    generateCheckoutCode,
    getProductTypes,
    postProductType,
    getProductCategory,
    postProductCategory,
    editProductCategory,
    updateProductType,
    closeMarketDemand,
    getTransactions
  };
}

// Create a singleton instance of the API service
const api = createApiService();

export default api;

//API HOOKS
export const useCustomQuery = ({ queryKey, queryFn, options }) =>
  useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    retryOnMount: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: "always",
    refetchIntervalInBackground: true,
    ...options,
  });

export const useCorporatePlans = (type) => {
  const {
    data: corps,
    refetch: refetchCorps,
    isLoading: isCorpsLoading,
  } = useCustomQuery({
    queryKey: ["getPlans", type],
    queryFn: () => api.getPlans(type),
  });

  return { corps, isCorpsLoading, refetchCorps };
};

export const useDemoRequests = () => {
  const dispatch = useDispatch();
  const {
    data: demos,
    refetch: refetchDemos,
    isLoading: isDemosLoading,
  } = useCustomQuery({
    queryKey: ["getDemoRequests"],
    queryFn: () => api.getDemoRequests(),
  });

  const {
    data: duration,
    refetch: refetchDuration,
    isLoading: isGettingDuration,
  } = useCustomQuery({
    queryKey: ["getDemoDuration"],
    queryFn: () => api.getDemoDuration(),
  });

  const deleteRequest = useMutation(api.deleteRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("getDemoRequests");
    },
  });

  const updateDemoDuration = useMutation(api.updateDemoDuration, {
    onSuccess: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  return {
    demos,
    duration,
    refetchDemos,
    deleteRequest,
    isDemosLoading,
    refetchDuration,
    isGettingDuration,
    updateDemoDuration,
  };
};

export const useProducts = ({ page, limit, status }) => {
  const dispatch = useDispatch();
  const {
    data,
    refetch: refetchProducts,
    isLoading: isProductsLoading,
  } = useCustomQuery({
    queryKey: ["getAllProducts"],
    queryFn: () =>
      api.getAllProducts({ page: page, limit: limit, status: status }),
  });

  const products = data?.product;
  const updateProductStatus = useMutation(api.updateProductStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProducts");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  return {
    data,
    products,
    refetchProducts,
    isProductsLoading,
    updateProductStatus,
  };
};
export const useProductCategory = (id) => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAllProductsCategory"],
    queryFn: () =>
      api.getProductCategory(id),
    options: {
      enabled: !!id
    }
  });

  const postProductCategory = useMutation(api.postProductCategory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProductsCategory");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  const updateProductCategory = useMutation(api.editProductCategory, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("getAllProductsCategory");
      dispatch(setProductCategoryDetails(data?.category))
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  return {
    data,
    isLoading,
    postProductCategory,
    updateProductCategory,
    refetch
  };
};

export const useProductTypes = () => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAllProductsTypes"],
    queryFn: () =>
      api.getProductTypes(),
  });

  const postProductType = useMutation(api.postProductType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProductsTypes");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  const updateProductType = useMutation(api.updateProductType,{
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProductsTypes");
       refetch()
       dispatch(setProductsTypeDetails(data))
       dispatch(
        showNotification({
          message: 'update successful!',
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });


  return {
    data,
    isLoading,
    postProductType,
  updateProductType,
    refetch
  };
};



export const useSellers = () => {
  const {
    data,
    refetch: refetchSellers,
    isLoading: isSellersLoading,
  } = useCustomQuery({
    queryKey: ["getSellers"],
    queryFn: () => api.getSellers(),
  });
  const sellers = data?.sellers;
  return {
    data,
    sellers,
    refetchSellers,
    isSellersLoading,
  };
};


export const useOrders = ({ page, limit }) => {
  const {
    data,
    refetch,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useMarketOrders"],
    queryFn: () => api.getAdminMarketOrders({ page, limit }),
  });
  return {
    data,
    refetch,
    isLoading,
  };
};

export const useMarketDemands = ({ page, limit }) => {
  const {
    data,
    refetch: refetchDemands,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useMarketDemands"],
    queryFn: () => api.getAdminMarketDemands({ page, limit }),
  });
  return {
    data,
    refetchDemands,
    isLoading,
  };
};


export const useClosedMarketDemands = ( limit, page,) => {
  const {
    data,
    refetch: refetchDemands,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useCloseMarketDemands", page, limit],
    queryFn: () => api.getAdminClosedDemands(page, limit),
  });
  return {
    data,
    refetchDemands,
    isLoading,
  };
};

export const useCompletedMarketDemand = (id) => {
  let {
    data,
    refetch,
    isLoading,
    isError,
    error
  } = useCustomQuery({
    queryKey: ["useCompletedMarketDemand"],
    queryFn: () => api.getCompletedMarketDemand(id),
    options: {
      enabled: !!id,
      
    }
  });

  ///
  return {
    data,
    demand: data?.data,
    refetch,
    isLoading,
    error,
    isError
  };
};

export const useSingleMarketDemand = (id) => {
  const dispatch = useDispatch();
  let {
    data,
    refetch,
    isLoading,
    isError,
    error
  } = useCustomQuery({
    queryKey: ["useSingleMarketDemand"],
    queryFn: () => api.getSingleMarketDemand(id),
    options: {
      enabled: !!id,
    }
  });

  const closeUnfillDemands = useMutation(api.closeMarketDemand, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey:'useMarketDemands'});
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  ///
  return {
    data,
    demand: data?.data,
    refetch,
    isLoading,
    error,
    isError,
    closeUnfillDemands 
  };
};

export const useDashboardCard = () => {
  // 
  let {
    data,
    refetch,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useTransactions"],
    queryFn: () => api.getTransactions(),
    options: {
    }
  });

  ///
  return {
    transaction:data?.data,
    transactionRefresh: refetch,
    isTransaction:isLoading,
    
  };
}

export const useSingleDemandSuppliers = (id) => {
  // 
  let {
    data,
    refetch,
    isLoading,
    isError,
    error,
    isFetching
  } = useCustomQuery({
    queryKey: ["getSingleDemandSuppliers"],
    queryFn: () => api.getSingleDemandSuppliers(id),
    options: {
      enabled: !!id,
    }
  });

  ///
  return {
    data,
    suppliers: data?.data,
    refetch,
    isLoading,
    error,
    isError,
    isFetching
  };
}

export const useAcceptSupplier = () => {
  const dispatch = useDispatch();

  const { isLoading, mutateAsync, } = useMutation(api.acceptSupplier, {
    onSuccess: (data) => {
      // console.log(data)
      queryClient.invalidateQueries({queryKey:["getSingleDemandSuppliers"]});
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  return {
    isLoading,
    mutateAsync
  };
};


export const useGenerateCode = () => {
  const dispatch = useDispatch();

  const { isLoading, mutateAsync, } = useMutation(api.generateCheckoutCode, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("getSingleDemandSuppliers");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message,
          status: 0,
        })
      );
    },
  });

  return {
    isSpinning: isLoading,
    mutateAsync
  };
};