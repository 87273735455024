export function isStrongPassword(password) {
  // Check if the password is at least 8 characters long
  if (password.length < 8) {
    return false;
  }

  // Regular expressions to check for uppercase letter, symbol, and number
  const uppercaseRegex = /[A-Z]/;
  const symbolRegex = /[\W_]/; // Matches any non-word character (including symbols)
  const numberRegex = /\d/;

  // Check if the password contains at least one uppercase letter
  if (!uppercaseRegex.test(password)) {
    return false;
  }

  // Check if the password contains at least one symbol
  if (!symbolRegex.test(password)) {
    return false;
  }

  // Check if the password contains at least one number
  if (!numberRegex.test(password)) {
    return false;
  }

  // If all checks pass, the password is strong
  return true;
}
export function checkPasswordStrength(password) {
  const result = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    symbol: /[\W_]/.test(password), // Matches any non-word character (including symbols)
    number: /\d/.test(password),
  };

  return result;
}

export const formatNaira = (amount) => {
  if(isNaN(amount)) {
    return 0
  }
  return "₦" + amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

// ====================================================================================================
/**
 * Formats a number as currency using the specified currency and locale.
 * @param {number} number - The number to format as currency.
 * @param {string} [currency="NGN"] - The currency to use for formatting. Defaults to "NGN".
 * @param {string} [locale="en-NG"] - The locale to use for formatting. Defaults to "en-NG".
 * @param {boolean} [showDecimal=false] - Whether to show decimal places. Defaults to false.
 * @returns {string} The formatted currency string.
 */
export function formatAsCurrency(number, options = {}) {
  const { currency = "NGN", locale = "en-NG", showDecimal = false } = options;

  const formatOptions = {
    style: "currency",
    currency: currency,
  };

  if (!showDecimal) {
    formatOptions.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat(locale, formatOptions).format(number);
}

export const statusColorMatch = {
  approved: "#1B7B44",
  pending: "#F68B1E",
  rejected: "#B81A0A",
  delivered: "#0099FF",
  received: "#55B802",
  canceled: "#EF0C1A",
  returned: "#9E3F00",
  buyers: "#445268",
  "in progress": "#BFB800",
  shipped: "#7549FF",
  "new orders": "#1B7B44",
};

export const PasswordStrengthIndicator = (password = "") => {
  const strength = checkPasswordStrength(password);
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Password Strength:</h3>
      <div className="flex items-center space-x-2">
        <div
          className={`flex-1 h-2 bg-${
            strength.length ? "green" : "gray"
          }-300 rounded`}
        ></div>
        <div
          className={`flex-1 h-2 bg-${
            strength.uppercase ? "green" : "gray"
          }-300 rounded`}
        ></div>
        <div
          className={`flex-1 h-2 bg-${
            strength.symbol ? "green" : "gray"
          }-300 rounded`}
        ></div>
        <div
          className={`flex-1 h-2 bg-${
            strength.number ? "green" : "gray"
          }-300 rounded`}
        ></div>
      </div>
      <div className="text-sm mt-1">
        <span
          className={`text-${
            strength.length &&
            strength.uppercase &&
            strength.symbol &&
            strength.number
              ? "green"
              : "gray"
          }-600`}
        >
          {strength.length &&
          strength.uppercase &&
          strength.symbol &&
          strength.number
            ? "Strong"
            : "Incomplete"}
        </span>
      </div>
    </div>
  );
};

export function convertToISODate(dateString) {
  // Create a dictionary to map month names to their numerical values
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  // Split the input date string into components
  const parts = dateString.split(" ");

  if (parts.length === 3) {
    const day = parts[0]; // Day part
    const month = months[parts[1].slice(0, 3)]; // Convert month name to number
    const year = parts[2]; // Year part

    // Assemble the ISO date-time string
    const isoDateString = `${year}-${month}-${day}T00:00:00.000Z`;

    return isoDateString;
  } else {
    throw new Error("Invalid date format");
  }
}
export function convertToCustomDateFormat(isoDateString) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(isoDateString);
  return date.toLocaleDateString("en-US", options);
}

export const getRoleComponent = (role) => {
  if (role === "super-dmin")
    return <div className="badge badge-accent">{role}</div>;
  if (role === "manager")
    return <div className="badge badge-secondary">{role}</div>;
  if (role === "owner") return <div className="badge">{role}</div>;
  if (role === "support")
    return <div className="badge badge-accent">{role}</div>;
  else return <div className="badge badge-ghost">{role}</div>;
};

export function truncateString(str, maxLength = 20) {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength - 3) + "...";
  }
  return str;
}

export const countries = [
  // { name: "Afghanistan", value: "Afghanistan" },
  // { name: "Albania", value: "Albania" },
  // { name: "Algeria", value: "Algeria" },
  // { name: "Andorra", value: "Andorra" },
  // { name: "Angola", value: "Angola" },
  // { name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  // { name: "Argentina", value: "Argentina" },
  // { name: "Armenia", value: "Armenia" },
  // { name: "Australia", value: "Australia" },
  // { name: "Austria", value: "Austria" },
  // { name: "Azerbaijan", value: "Azerbaijan" },
  // { name: "Bahamas", value: "Bahamas" },
  // { name: "Bahrain", value: "Bahrain" },
  // { name: "Bangladesh", value: "Bangladesh" },
  // { name: "Barbados", value: "Barbados" },
  // { name: "Belarus", value: "Belarus" },
  // { name: "Belgium", value: "Belgium" },
  // { name: "Belize", value: "Belize" },
  // { name: "Benin", value: "Benin" },
  // { name: "Bhutan", value: "Bhutan" },
  // { name: "Bolivia", value: "Bolivia" },
  // { name: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  // { name: "Botswana", value: "Botswana" },
  // { name: "Brazil", value: "Brazil" },
  // { name: "Brunei", value: "Brunei" },
  // { name: "Bulgaria", value: "Bulgaria" },
  // { name: "Burkina Faso", value: "Burkina Faso" },
  // { name: "Burundi", value: "Burundi" },
  // { name: "Cambodia", value: "Cambodia" },
  // { name: "Cameroon", value: "Cameroon" },
  // { name: "Canada", value: "Canada" },
  // { name: "Cape Verde", value: "Cape Verde" },
  // { name: "Central African Republic", value: "Central African Republic" },
  // { name: "Chad", value: "Chad" },
  // { name: "Chile", value: "Chile" },
  // { name: "China", value: "China" },
  // { name: "Colombia", value: "Colombia" },
  // { name: "Comoros", value: "Comoros" },
  // { name: "Congo (Brazzaville)", value: "Congo (Brazzaville)" },
  // { name: "Congo (Kinshasa)", value: "Congo (Kinshasa)" },
  // { name: "Costa Rica", value: "Costa Rica" },
  // { name: "Croatia", value: "Croatia" },
  // { name: "Cuba", value: "Cuba" },
  // { name: "Cyprus", value: "Cyprus" },
  // { name: "Czech Republic", value: "Czech Republic" },
  // { name: "Denmark", value: "Denmark" },
  // { name: "Djibouti", value: "Djibouti" },
  // { name: "Dominica", value: "Dominica" },
  // { name: "Dominican Republic", value: "Dominican Republic" },
  // { name: "East Timor", value: "East Timor" },
  // { name: "Ecuador", value: "Ecuador" },
  // { name: "Egypt", value: "Egypt" },
  // { name: "El Salvador", value: "El Salvador" },
  // { name: "Equatorial Guinea", value: "Equatorial Guinea" },
  // { name: "Eritrea", value: "Eritrea" },
  // { name: "Estonia", value: "Estonia" },
  // { name: "Ethiopia", value: "Ethiopia" },
  // { name: "Fiji", value: "Fiji" },
  // { name: "Finland", value: "Finland" },
  // { name: "France", value: "France" },
  // { name: "Gabon", value: "Gabon" },
  // { name: "Gambia", value: "Gambia" },
  // { name: "Georgia", value: "Georgia" },
  // { name: "Germany", value: "Germany" },
  // { name: "Ghana", value: "Ghana" },
  // { name: "Greece", value: "Greece" },
  // { name: "Grenada", value: "Grenada" },
  // { name: "Guatemala", value: "Guatemala" },
  // { name: "Guinea", value: "Guinea" },
  // { name: "Guinea-Bissau", value: "Guinea-Bissau" },
  // { name: "Guyana", value: "Guyana" },
  // { name: "Haiti", value: "Haiti" },
  // { name: "Honduras", value: "Honduras" },
  // { name: "Hungary", value: "Hungary" },
  // { name: "Iceland", value: "Iceland" },
  // { name: "India", value: "India" },
  // { name: "Indonesia", value: "Indonesia" },
  // { name: "Iran", value: "Iran" },
  // { name: "Iraq", value: "Iraq" },
  // { name: "Ireland", value: "Ireland" },
  // { name: "Israel", value: "Israel" },
  // { name: "Italy", value: "Italy" },
  // { name: "Ivory Coast", value: "Ivory Coast" },
  // { name: "Jamaica", value: "Jamaica" },
  // { name: "Japan", value: "Japan" },
  // { name: "Jordan", value: "Jordan" },
  // { name: "Kazakhstan", value: "Kazakhstan" },
  // { name: "Kenya", value: "Kenya" },
  // { name: "Kiribati", value: "Kiribati" },
  // { name: "Kosovo", value: "Kosovo" },
  // { name: "Kuwait", value: "Kuwait" },
  // { name: "Kyrgyzstan", value: "Kyrgyzstan" },
  // { name: "Laos", value: "Laos" },
  // { name: "Latvia", value: "Latvia" },
  // { name: "Lebanon", value: "Lebanon" },
  // { name: "Lesotho", value: "Lesotho" },
  // { name: "Liberia", value: "Liberia" },
  // { name: "Libya", value: "Libya" },
  // { name: "Liechtenstein", value: "Liechtenstein" },
  // { name: "Lithuania", value: "Lithuania" },
  // { name: "Luxembourg", value: "Luxembourg" },
  // { name: "Macedonia", value: "Macedonia" },
  // { name: "Madagascar", value: "Madagascar" },
  // { name: "Malawi", value: "Malawi" },
  // { name: "Malaysia", value: "Malaysia" },
  // { name: "Maldives", value: "Maldives" },
  // { name: "Mali", value: "Mali" },
  // { name: "Malta", value: "Malta" },
  // { name: "Marshall Islands", value: "Marshall Islands" },
  // { name: "Mauritania", value: "Mauritania" },
  // { name: "Mauritius", value: "Mauritius" },
  // { name: "Mexico", value: "Mexico" },
  // { name: "Micronesia", value: "Micronesia" },
  // { name: "Moldova", value: "Moldova" },
  // { name: "Monaco", value: "Monaco" },
  // { name: "Mongolia", value: "Mongolia" },
  // { name: "Montenegro", value: "Montenegro" },
  // { name: "Morocco", value: "Morocco" },
  // { name: "Mozambique", value: "Mozambique" },
  // { name: "Myanmar", value: "Myanmar" },
  // { name: "Namibia", value: "Namibia" },
  // { name: "Nauru", value: "Nauru" },
  // { name: "Nepal", value: "Nepal" },
  // { name: "Netherlands", value: "Netherlands" },
  // { name: "New Zealand", value: "New Zealand" },
  // { name: "Nicaragua", value: "Nicaragua" },
  // { name: "Niger", value: "Niger" },
  { name: "Nigeria", value: "Nigeria" },
  { name: "North Korea", value: "North Korea" },
  { name: "Norway", value: "Norway" },
  { name: "Oman", value: "Oman" },
  { name: "Pakistan", value: "Pakistan" },
  { name: "Palau", value: "Palau" },
  { name: "Palestine", value: "Palestine" },
  { name: "Panama", value: "Panama" },
  { name: "Papua New Guinea", value: "Papua New Guinea" },
  { name: "Paraguay", value: "Paraguay" },
  { name: "Peru", value: "Peru" },
  { name: "Philippines", value: "Philippines" },
  { name: "Poland", value: "Poland" },
  { name: "Portugal", value: "Portugal" },
  { name: "Qatar", value: "Qatar" },
  { name: "Romania", value: "Romania" },
  { name: "Russia", value: "Russia" },
  { name: "Rwanda", value: "Rwanda" },
  { name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { name: "Saint Lucia", value: "Saint Lucia" },
  {
    name: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { name: "Samoa", value: "Samoa" },
  { name: "San Marino", value: "San Marino" },
  { name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { name: "Saudi Arabia", value: "Saudi Arabia" },
  { name: "Senegal", value: "Senegal" },
  { name: "Serbia", value: "Serbia" },
  { name: "Seychelles", value: "Seychelles" },
  { name: "Sierra Leone", value: "Sierra Leone" },
  { name: "Singapore", value: "Singapore" },
  { name: "Slovakia", value: "Slovakia" },
  { name: "Slovenia", value: "Slovenia" },
  { name: "Solomon Islands", value: "Solomon Islands" },
  { name: "Somalia", value: "Somalia" },
  { name: "South Africa", value: "South Africa" },
  { name: "South Korea", value: "South Korea" },
  { name: "South Sudan", value: "South Sudan" },
  { name: "Spain", value: "Spain" },
  { name: "Sri Lanka", value: "Sri Lanka" },
  { name: "Sudan", value: "Sudan" },
  { name: "Suriname", value: "Suriname" },
  { name: "Swaziland", value: "Swaziland" },
  { name: "Sweden", value: "Sweden" },
  { name: "Switzerland", value: "Switzerland" },
  { name: "Syria", value: "Syria" },
  { name: "Taiwan", value: "Taiwan" },
  { name: "Tajikistan", value: "Tajikistan" },
  { name: "Tanzania", value: "Tanzania" },
  { name: "Thailand", value: "Thailand" },
  { name: "Togo", value: "Togo" },
  { name: "Tonga", value: "Tonga" },
  { name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { name: "Tunisia", value: "Tunisia" },
  { name: "Turkey", value: "Turkey" },
  { name: "Turkmenistan", value: "Turkmenistan" },
  { name: "Tuvalu", value: "Tuvalu" },
  { name: "Uganda", value: "Uganda" },
  { name: "Ukraine", value: "Ukraine" },
  { name: "United Arab Emirates", value: "United Arab Emirates" },
  { name: "United Kingdom", value: "United Kingdom" },
  { name: "United States of America", value: "United States of America" },
  { name: "Uruguay", value: "Uruguay" },
  { name: "Uzbekistan", value: "Uzbekistan" },
  { name: "Vanuatu", value: "Vanuatu" },
  { name: "Vatican City", value: "Vatican City" },
  { name: "Venezuela", value: "Venezuela" },
  { name: "Vietnam", value: "Vietnam" },
  { name: "Yemen", value: "Yemen" },
  { name: "Zambia", value: "Zambia" },
  { name: "Zimbabwe", value: "Zimbabwe" },
];

export const nigeriaStates = [
  { name: "Abia", value: "Abia" },
  { name: "Adamawa", value: "Adamawa" },
  { name: "Akwa Ibom", value: "Akwa Ibom" },
  { name: "Anambra", value: "Anambra" },
  { name: "Bauchi", value: "Bauchi" },
  { name: "Bayelsa", value: "Bayelsa" },
  { name: "Benue", value: "Benue" },
  { name: "Borno", value: "Borno" },
  { name: "Cross River", value: "Cross River" },
  { name: "Delta", value: "Delta" },
  { name: "Ebonyi", value: "Ebonyi" },
  { name: "Edo", value: "Edo" },
  { name: "Ekiti", value: "Ekiti" },
  { name: "Enugu", value: "Enugu" },
  { name: "FCT (Abuja)", value: "FCT (Abuja)" },
  { name: "Gombe", value: "Gombe" },
  { name: "Imo", value: "Imo" },
  { name: "Jigawa", value: "Jigawa" },
  { name: "Kaduna", value: "Kaduna" },
  { name: "Kano", value: "Kano" },
  { name: "Katsina", value: "Katsina" },
  { name: "Kebbi", value: "Kebbi" },
  { name: "Kogi", value: "Kogi" },
  { name: "Kwara", value: "Kwara" },
  { name: "Lagos", value: "Lagos" },
  { name: "Nasarawa", value: "Nasarawa" },
  { name: "Niger", value: "Niger" },
  { name: "Ogun", value: "Ogun" },
  { name: "Ondo", value: "Ondo" },
  { name: "Osun", value: "Osun" },
  { name: "Oyo", value: "Oyo" },
  { name: "Plateau", value: "Plateau" },
  { name: "Rivers", value: "Rivers" },
  { name: "Sokoto", value: "Sokoto" },
  { name: "Taraba", value: "Taraba" },
  { name: "Yobe", value: "Yobe" },
  { name: "Zamfara", value: "Zamfara" },
];
