import { createSlice } from '@reduxjs/toolkit'

export const rightDrawerSlice = createSlice({
    name: 'rightDrawer',
    initialState: {
        header: "",  // current  title state management
        isOpen : false,   // right drawer state management for opening closing
        bodyType : "",   // right drawer content management
        extraObject : {}, 
        data:null  
    },
    reducers: {

        openRightDrawer: (state, action) => {
            const {header, bodyType, extraObject, data} = action.payload
            state.isOpen = true
            state.bodyType = bodyType
            state.header = header
            state.extraObject = extraObject
            state.data = data
        },

        closeRightDrawer: (state, action) => {
            state.isOpen = false
            state.bodyType = ""
            state.header = ""
            state.extraObject = {}
            state.data = null
        },

    }
})

export const { openRightDrawer, closeRightDrawer } = rightDrawerSlice.actions

export default rightDrawerSlice.reducer