import Subtitle from "../Typography/Subtitle";

function TitleCard({
  title,
  classes,
  children,
  topMargin,
  noTitle,
  cardWidth,
  otherTopItems,
  TopSideButtons,
}) {
  return (
    <div
      className={
        `${classes} card ${
          noTitle ? "p-3" : " p-6"
        } bg-base-100 shadow-xl mx-auto ` +
        (topMargin || "mt-6") +
        (cardWidth || "w-full")
      }
    >
      {/* Title for Card */}
      {!noTitle && (
        <>
          <Subtitle
            styleClass={TopSideButtons ? "inline-block max-[800px]:grid" : ""}
          >
            <span className="capitalize">{title}</span>

            {/* Add other items in between */}
            {otherTopItems && (
              <div className="inline-flex items-center lg:ml-10 justify-end bg-base-500 w-full flex-col lg:flex-row my-4 lg:my-0 lg:w-3/5 ">
                {otherTopItems}
              </div>
            )}
            {/* Top side button, show only if present */}
            {TopSideButtons && (
              <div className="inline-block float-right">{TopSideButtons}</div>
            )}
          </Subtitle>

          <div className="divider mt-2"></div>
        </>
      )}

      {/** Card Body */}
      <div
        className={`h-full w-full ${
          noTitle ? "pb-0" : "pb-6"
        } bg-base-100 capitalize`}
      >
        {children}
      </div>
    </div>
  );
}

export default TitleCard;
