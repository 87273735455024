import { createSlice } from "@reduxjs/toolkit";

export const corpsSlice = createSlice({
  name: "corporate",
  initialState: {
    isLoadingCorps: false,
    corpEntities: [],
  },
  reducers: {
    setIsLoadingCorps: (state, action) => {
      state.isLoadingCorps = action.payload;
    },
    setCorpEntities: (state, action) => {
      state.corpEntities = action.payload;
    },
  },
});

export const { setCorpEntities, setIsLoadingCorps } = corpsSlice.actions;

//write funtions to get the state value
export const getcorpEntities = (state) => state.corpsSlice.corpEntities;

export default corpsSlice.reducer;
