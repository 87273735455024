import { combineReducers } from "redux";

import usersSlice from "../../features/users/usersSlice";
import modalSlice from "../../features/common/modalSlice";
import headerSlice from "../../features/common/headerSlice";
import rightDrawerSlice from "../../features/common/rightDrawerSlice";
import corpsSlice from "./slices/corpSlice";
import demoRequestSlice from "./slices/demoRequestSlice";
import productSlice from "./slices/productSlice";

const reducers = combineReducers({
  user: usersSlice,
  modal: modalSlice,
  header: headerSlice,
  corpsSlice: corpsSlice,
  products: productSlice,
  rightDrawer: rightDrawerSlice,
  demoRequestSlice: demoRequestSlice,
});

export default reducers;
