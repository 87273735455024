import { useDispatch } from "react-redux";

import { openModal } from "features/common/modalSlice";
import { MODAL_BODY_TYPES } from "utils/globalConstantUtil";
import { useEffect, useState } from "react";
import SearchBar from "components/Input/SearchBar";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import FunnelIcon from "@heroicons/react/24/outline/FunnelIcon";
import { convertToCustomDateFormat } from "utils/factory";
import Datepicker from "react-tailwindcss-datepicker";

export default function TopSideButtons() {
  const dispatch = useDispatch();

  const openAddNewUserModal = () => {
    dispatch(
      openModal({
        title: "Add New User",
        bodyType: MODAL_BODY_TYPES.USER_ADD_NEW,
      })
    );
  };

  return (
    <div className="inline-block float-right">
      <button
        className="btn px-6 btn-sm normal-case btn-primary"
        onClick={() => openAddNewUserModal()}
      >
        Add New
      </button>
    </div>
  );
}

export const TopSideButtonsTwo = ({
  removeFilter = () => { },
  applyFilter = () => { },
  applySearch = () => { },
  dateValue,
  handleDatePickerValueChange = () => { },
}) => {
  const [filterParam, setFilterParam] = useState("");
  const [dateFilterParam, setDateFilterParam] = useState(false);
  const [searchText, setSearchText] = useState("");
  const statusFilters = ["Pending", "Active", "Deleted", "Suspended"];
  const [today] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam("");
    setSearchText("");
    setDateFilterParam(false);
  };
  useEffect(() => {
    if (
      convertToCustomDateFormat(dateValue?.startDate) !==
      convertToCustomDateFormat(today?.startDate)
    ) {
      setDateFilterParam(true);
    } else {
      setDateFilterParam(false);
    }
  }, [dateValue, today]);

  useEffect(() => {
    if (searchText === "") {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="grid gap-4 place-items-end lg:gap-0 mt-4 lg:mt-0 lg:flex  w-full md:w-[700px] items-center float-right">
      <SearchBar
        searchText={searchText}
        styleClass="lg:mr-4 w-full flex-1"
        setSearchText={setSearchText}
        placeholderText={"Search company by name or plan..."}
      />
      {filterParam !== "" && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end mr-4">
        <label tabIndex={0} className="btn !text-xs btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2 max-[800px]:hidden " />
          Filter
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {statusFilters.map((l, k) => {
            return (
              <li key={k}>
                <button onClick={() => showFiltersAndApply(l)}>{l}</button>
              </li>
            );
          })}
          <div className="divider mt-0 mb-0"></div>
          <li>
            <button onClick={() => removeAppliedFilter()}>Remove Filter</button>
          </li>
        </ul>
      </div>
      <Datepicker
        theme={"light"}
        value={dateValue}
        showShortcuts={true}
        primaryColor={"white"}
        popoverDirection={"down"}
        toggleClassName="invisible"
        containerClassName="w-full lg:w-max  !border-none"
        onChange={handleDatePickerValueChange}
        inputClassName="input input-bordered h-max py-1.5  w-full lg:w-56  text-center text-sm"
      />
      {dateFilterParam === true && (
        <button
          onClick={() => {
            removeAppliedFilter();
            handleDatePickerValueChange(today);
          }}
          className="btn btn-xs  btn-active btn-error normal-case  "
        >
          <XMarkIcon className="w-4 text-white " />
        </button>
      )}
    </div>
  );
};
