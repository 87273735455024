import React from "react";

function SearchBar({
  searchText,
  styleClass,
  inputClass,
  placeholderText,
  setSearchText,
}) {
  const updateSearchInput = (value) => {
    setSearchText(value);
  };

  return (
    <div className={"inline-block " + styleClass}>
      <div className="input-group  relative flex flex-wrap rounded-md  items-stretch w-full ">
        <input
          type="search"
          value={searchText}
          placeholder={placeholderText || "Search"}
          onChange={({ target: { value } }) => updateSearchInput(value)}
          className={`input input-sm input-bordered !rounded-full w-full max-w-lg ${inputClass}`}
        />
      </div>
    </div>
  );
}

export default SearchBar;
