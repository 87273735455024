import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { TEAM_MEMBERS } from "../settings/team";
import api from "app/api-services";
import { useQuery } from "@tanstack/react-query";

// export const getUsersContent = createAsyncThunk("/users/content", async () => {
//   const response = await axios.get("/api/users?page=2", {});
//   return response.data;
// });

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    users: [],
    currentUser: {},
    teamMembers: [],
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addNewUser: (state, action) => {
      let { newUserObj } = action.payload;
      state.users = [...state.users, newUserObj];
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },

    deleteUser: (state, action) => {
      let { index } = action.payload;
      state.users.splice(index, 1);
    },

    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },

    addNewTeamMember: (state, action) => {
      let { newMemberObj } = action.payload;
      state.teamMembers = [...state.teamMembers, newMemberObj];
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
  },

  // extraReducers: {
  //   [getUsersContent.pending]: (state) => {
  //     state.isLoading = true;
  //   },
  //   [getUsersContent.fulfilled]: (state, action) => {
  //     state.users = action.payload.data;
  //     state.isLoading = false;
  //   },
  //   [getUsersContent.rejected]: (state) => {
  //     state.isLoading = false;
  //   },
  // },
});

export const {
  setUsers,
  addNewUser,
  deleteUser,
  setIsLoading,
  setCurrentUser,
  addNewTeamMember,
  setTeamMembers,
} = usersSlice.actions;

//write funtions to get the state value
export const getUser = (state) => state.usersSlice;
export const getUsers = (state) => state.usersSlice.users;
export const getCurrentUser = (state) => state.user.currentUser;
export const getTeamMembers = (state) => state.user.teamMembers;

export default usersSlice.reducer;
