import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";

import combinedReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [""], //specify reducers that should not be persisted
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  //   this will intercept and stop non-serializable values in action before they get to the reducer
  //  When using Redux Persist without using the Thunk middleware, we‘d get an error in the browser’s console reading a non-serializable value was detected in the state
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
