// import moment from "moment";
import {
  useEffect,
  // useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleCard from "../../../components/Cards/TitleCard";
import { showNotification } from "../../common/headerSlice";
import {
  CONFIRMATION_MODAL_CLOSE_TYPES,
  MODAL_BODY_TYPES,
} from "utils/globalConstantUtil";
import {
  useMutation,
  // useQueries,
  useQuery,
} from "@tanstack/react-query";
import api from "app/api-services";
import {
  truncateString,
  getRoleComponent,
  convertToCustomDateFormat,
} from "utils/factory";
import ReactPaginate from "react-paginate";
import SearchBar from "components/Input/SearchBar";
import { openModal } from "features/common/modalSlice";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { getTeamMembers, setTeamMembers } from "features/users/usersSlice";
import { TopSideButtonsTwo } from "features/users/components/TopSideButton";

const TopSideButtons = () => {
  const {
    data: roles,
    isLoading,
    refetch,
  } = useQuery(["ADMIN_ROLES"], () => api.getAdminRoles());
  const { refetch: refetchAdmins } = useQuery(
    ["SUB_ADMINS"],
    () => api.getAllSubAdmin(),
    {
      onSuccess: (data) => {
        dispatch(setTeamMembers([...data?.Active, ...data?.Inactive]));
      },
    }
  );

  if (!isLoading) {
  }
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const addMember = useMutation((data) => api.registerAdmin(data), {
    onSuccess: (res) => {
      dispatch(showNotification({ message: res?.message, status: 1 }));
      setEmail("");

      //refetch
      refetchAdmins();
      refetch();
    },
    onError: (err) => {
      dispatch(
        showNotification({
          message:
            err?.response?.data?.message || "Something went wrong, Try again!",
          status: 0,
        })
      );
    },
  });

  const addNewTeamMember = async (e) => {
    e.preventDefault();
    if (email?.trim() === "")
      return dispatch(
        showNotification({ message: "Enter admin email!", status: 0 })
      );
    if (role?.trim() === "")
      return dispatch(
        showNotification({ message: "Select admin role!", status: 0 })
      );
    else {
      const payload = { email, role };
      await addMember.mutateAsync(payload);
    }
  };

  return (
    <div className="flex w-full items-end lg:items-center justify-end flex-col lg:flex-row  lg:gap-11 float-right">
      <div className="flex w-full items-center justify-between md:w-[600px]  gap-6 bg-base-200 my-4 lg:my-0 rounded-full">
        <SearchBar
          searchText={email}
          styleClass="mr-4 flex-1"
          setSearchText={setEmail}
          placeholderText={"invite admins by email..."}
        />
        <select
          className={
            "select pr-4 select-ghost select-xs outline-none focus:bg-transparent focus:outline-none border-none p-0 h-0 " +
            (isLoading ? " loading" : "")
          }
          value={role}
          onChange={({ target: { value } }) => setRole(value)}
        >
          <option value={"Role"} className="text-xs text-slate-800 bg-base-200">
            {"Role"}
          </option>
          {roles?.map(({ role, _id }, idx) => (
            <option key={idx} value={_id} className="text-xs">
              {role}
            </option>
          ))}
        </select>
      </div>
      <button
        disabled={addMember.isLoading}
        className={
          "btn px-6 btn-sm normal-case btn-primary" +
          (addMember.isLoading ? " loading" : "")
        }
        onClick={addNewTeamMember}
      >
        Invite New
      </button>
    </div>
  );
};

function Team() {
  const teamMembers = useSelector(getTeamMembers);
  const dispatch = useDispatch();

  const [filteredUsers, setFilteredUsers] = useState(teamMembers);

  //   Start for Pagination
  const [PerItem, setPerItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const offset = currentPage * Number(PerItem);
  const currentItem = filteredUsers?.slice(offset, offset + Number(PerItem));
  const pageCount = Math.ceil(filteredUsers?.length / Number(PerItem));

  const removeFilter = () => {
    setFilteredUsers(teamMembers);
  };

  const applyFilter = (params) => {
    let filteredUser = teamMembers.filter((t) => {
      return t.status?.toLowerCase() === params?.toLowerCase();
    });
    setFilteredUsers(filteredUser);
  };

  // Search according to name
  const applySearch = (value) => {
    let filteredUser = filteredUsers.filter((t) => {
      return (
        t.email.toLowerCase().includes(value.toLowerCase()) ||
        t.email.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredUsers(filteredUser);
  };
  const updateAdminStatus = (status, id) => {
    dispatch(
      openModal({
        title: "Confirmation",
        bodyType: MODAL_BODY_TYPES.CONFIRMATION,
        extraObject: {
          message: `Are you sure you want to ${status?.toLowerCase()} this admin?`,
          type: CONFIRMATION_MODAL_CLOSE_TYPES.SUB_ADMIN_STATUS_UPDATE,
          subAdminPayload: {
            status: status === "Suspend" ? "suspended" : status,
            id: id,
          },
        },
      })
    );
  };

  useEffect(() => {
    setFilteredUsers(teamMembers);
  }, [teamMembers]);

  const statusSwitch = {
    active: ["Suspend"],
    suspended: [],
    pending: [],
    deleted: [],
  };
  return (
    <div>
      <TitleCard
        title="Active Members"
        topMargin="mt-2"
        cardWidth="w-full"
        TopSideButtons={<TopSideButtons />}
      >
        <div className="mb-4">
          <TopSideButtonsTwo
            applySearch={applySearch}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
          />
        </div>
        <br />
        {/* Team Member list in table format loaded constant */}
        <div className="overflow-x-auto w-full">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Joined On</th>
                <th>Role</th>
                <th>Status</th>
                <th>Last Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItem?.length > 0 ? (
                currentItem?.map((l, k) => {
                  return (
                    <tr key={k}>
                      <td className="sticky  left-0 z-11">
                        <div className="flex items-center space-x-3">
                          <div className="avatar">
                            {/* <div className="mask mask-circle w-12 h-12">
                            <img src={l.avatar} alt="Avatar" />
                          </div> */}
                            <div className="relative w-6 h-6 mask mask-circle overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                              <svg
                                className="w-8 h-8 text-gray-400 absolute top-0 -left-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <div className="font-bold">
                              {truncateString(l.full_name, 20) || "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{truncateString(l.email) || "N/A"}</td>
                      <td>{convertToCustomDateFormat(l.createdAt)}</td>
                      <td>
                        {getRoleComponent(
                          truncateString(l.admin_role?.role, 10) || "N/A"
                        )}
                      </td>
                      <td>
                        {statusSwitch[l.status?.toLowerCase()]?.length > 0 ? (
                          <select
                            onChange={({ target: { value } }) =>
                              updateAdminStatus(value, l._id)
                            }
                            className="select select-xs select-bordered w-full max-w-xs"
                          >
                            <option disabled selected>
                              {l.status}
                            </option>
                            {statusSwitch[l.status?.toLowerCase()]?.map(
                              (status) => (
                                <option>{status}</option>
                              )
                            )}
                          </select>
                        ) : (
                          l.status || "N/A"
                        )}
                      </td>
                      <td>{"N/A"}</td>
                      <td>
                        {!["active", "pending"].includes(
                          l.status?.toLowerCase()
                        ) ? (
                          <button
                            title="Restore"
                            className="btn btn-square btn-ghost"
                            onClick={() => updateAdminStatus("active", l._id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            title="Delete"
                            className="btn btn-square btn-ghost"
                            onClick={() => updateAdminStatus("deleted", l._id)}
                          >
                            <TrashIcon className="w-5 text-red-700" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="h-40 w-full flex items-center justify-center text-lg  text-slate-400 dark:text-gray-200 ">
                  {"No User!"}
                </div>
              )}
            </tbody>
          </table>
        </div>
        {filteredUsers?.length > 0 && (
          <div className="w-full flex flex-col lg:flex-row lg:justify-between py-4 mt-4 lg:mt-8">
            <div className="">
              <div className="md:flex w-72 md:items-center px-2 md:px-0 space-y-2 md:space-y-0 md:space-x-4 mt-10 md:mt-0 ">
                <label
                  htmlFor="select"
                  className="block text-sm font-medium text-gray-700"
                >
                  Showing
                </label>
                <select
                  value={PerItem}
                  onChange={(e) => setPerItem(e.target.value)}
                  id="select"
                  aria-label="form-select-sm"
                  className="block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:border-indigo-300 sm:text-sm"
                >
                  <option disabled value="">
                    --Select--
                  </option>
                  <option disabled={filteredUsers?.length < 5} value={5}>
                    5
                  </option>
                  <option disabled={filteredUsers?.length < 5} value={10}>
                    10
                  </option>
                  <option disabled={filteredUsers?.length < 10} value={20}>
                    20
                  </option>
                  <option disabled={filteredUsers?.length < 20} value={50}>
                    30
                  </option>
                </select>
                <span className="text-sm text-gray-500 flex w-80">
                  of {filteredUsers?.length} Users
                </span>
              </div>
            </div>
            <div className=" ">
              <div className="mt-8 md:mt-0">
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  containerClassName={
                    "flex border border-slate-400 px-2 py-1 rounded-lg items-center space-x-3"
                  }
                  previousLinkClassName={"text-slate-400 text-sm"}
                  nextLinkClassName={"text-slate-400 text-sm"}
                  disabledClassName={"text-slate-400"}
                  activeClassName={
                    "text-primary font-bold px-3 py-1 border  border-slate-400  rounded-lg space-x-2"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </TitleCard>
    </div>
  );
}

export default Team;
