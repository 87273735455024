module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    USER_DETAIL: "USER_DETAIL",
    USER_ADD_NEW: "USER_ADD_NEW",
    CONFIRMATION: "CONFIRMATION",
    DEFAULT: "",
  },

  RIGHT_DRAWER_TYPES: {
    NOTIFICATION: "NOTIFICATION",
    CALENDAR_EVENTS: "CALENDAR_EVENTS",
    SELLERPAYMENT:"SELLERPAYMENT"
  },

  CONFIRMATION_MODAL_CLOSE_TYPES: {
    USER_DELETE: "USER_DELETE",
    SUB_ADMIN_DELETE: "SUB_ADMIN_DELETE",
    RESTORE_SUB_ADMIN: "RESTORE_SUB_ADMIN",
    UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
    UPDATE_PRODUCT_STATUS: "UPDATE_PRODUCT_STATUS",
    SUB_ADMIN_STATUS_UPDATE: "SUB_ADMIN_STATUS_UPDATE",
    UPGRADE_CORP_ACCOUNT: "UPGRADE_CORP_ACCOUNT",
  },
});
