import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    isLoadingProducts: false,
    products: [],
    productDetails: {},
    productTypeDetails:null,
    productCategoryDetails: null
  },
  reducers: {
    setIsLoadingProducts: (state, action) => {
      state.isLoadingProducts = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductsDetails: (state, action) => {
      state.productDetails = action.payload;
    },

    setProductsTypeDetails: (state, action) => {
      state.productTypeDetails = action.payload;
    },

    setProductCategoryDetails: (state, action) =>{
      state.productCategoryDetails = action.payload
    }
  },
});

export const {
  setProducts,
  setIsLoadingProducts: setIsLoadingDemos,
  setProductsDetails,
  setProductsTypeDetails,
  setProductCategoryDetails
} = productSlice.actions;

//write funtions to get the state value
export const getProducts = (state) => state.productSlice;

export default productSlice.reducer;
