import { createSlice } from "@reduxjs/toolkit";

export const demoRequestSlice = createSlice({
  name: "demoRequest",
  initialState: {
    isLoadingDemos: false,
    demoRequests: [],
    demoRequestDetails: {},
  },
  reducers: {
    setIsLoadingDemos: (state, action) => {
      state.isLoadingDemos = action.payload;
    },
    setDemosRequests: (state, action) => {
      state.demoRequests = action.payload;
    },
    setDemosRequestsDetails: (state, action) => {
      state.demoRequestDetails = action.payload;
    },
  },
});

export const { setDemosRequests, setIsLoadingDemos, setDemosRequestsDetails } =
  demoRequestSlice.actions;

//write funtions to get the state value
export const getDemosRequests = (state) => state.demoRequestSlice;

export default demoRequestSlice.reducer;
