import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";

import "./index.css";
import App from "./App";
import { store, persistor } from "./app/redux/store";
import reportWebVitals from "./reportWebVitals";
import SuspenseContent from "./containers/SuspenseContent";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    // mutations:{
    // },
    queries: {
      cacheTime: 24 * 60 * 60 * 1000, //24 hours
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<SuspenseContent />}>
    {/* // Provide the client to your App */}
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
